<!--名称修改组件-->
<template>
  <div id="nameupdate">
    <span>{{ title }}：</span>
    <div class="change">
      <transition name="slide-fade">
        <div class="show" v-if="initial">
          <span>{{ name }}</span>
          <a-icon
            type="form"
            :style="{
              fontSize: '15px',
              color: '#7682CE',
              'margin-left': '15px',
            }"
            @click="StateChanges"
          />
        </div>
      </transition>
      <transition name="slide-fade">
        <div class="update" v-if="alter">
          <a-input
            ref="input"
            v-model="savename"
            class="update-input"
            size="small"
            :maxLength="maxLength"
          />
          <a-button size="small" type="primary" ghost @click="changestate"
            >取消</a-button
          >
          <a-button
            size="small"
            type="primary"
            @click="confirm"
            >确认</a-button
          >
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    name: {
      type: String,
    },
    state: {
      type: Boolean,
    },
    loading:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      initial: true,
      alter: false,
      savename: "",
      maxLength: 32,
    };
  },
  watch: {
    state: function () {
      if (this.state) {
        this.changestate();
      }
    },
    name: function(){
      this.savename = this.name;
    }
  },
  methods: {
    changestate() {
      this.initial = !this.initial;
      this.alter = !this.alter;
    },
    StateChanges() {
      this.initial = !this.initial;
      this.alter = !this.alter;
      setTimeout(() => {
        this.$refs.input.focus();
      }, 5);
    },
    confirm() {
      if(this.savename != this.name){
        this.$emit("confirm", this.savename);
      }
    },
  },
};
</script>

<style scoped>
#nameupdate {
  height: 40px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.change {
  height: 40px;
  position: relative;
}
.show {
  position: absolute;
  height: 40px;
  line-height: 40px;
  white-space: nowrap;
}
.update {
  position: absolute;
  height: 40px;
  line-height: 40px;
  white-space: nowrap;
}
.update-input {
  width: 150px;
  margin-right: 15px;
}
button {
  width: 90px;
  margin: 0px 10px;
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>